import axios from "axios";
import type { AxiosInstance, AxiosRequestConfig } from "axios";
import Cognito from "./cognito";
import type { CognitoUserSession } from "amazon-cognito-identity-js";

// ランダムなUUIDを生成する関数
const generateUUID = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const apiClient: AxiosInstance = axios.create({
  // APIのURI
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // リクエストヘッダ
  headers: {
    "Content-type": "application/json",
  },
});

// リクエストインターセプターを追加
apiClient.interceptors.request.use(async (config: AxiosRequestConfig) => {
  // x-api-keyを毎回生成
  if (config.headers) {
    config.headers["x-api-key"] = generateUUID();
  }

  const currentUser = Cognito.userPool.getCurrentUser();
  if (currentUser) {
    return new Promise((resolve, reject) => {
      currentUser.getSession((error: Error | null, session: CognitoUserSession | null) => {
        if (error) {
          reject(error);
          return;
        }
        if (!session) {
          reject(new Error("No session"));
          return;
        }
        // アクセストークンをAuthorizationヘッダーに設定
        const accessToken = session.getAccessToken().getJwtToken();
        if (config.headers) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        resolve(config);
      });
    });
  }
  return config;
});

export default apiClient;
